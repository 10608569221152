import { AttributeValue } from "@aws-sdk/client-dynamodb";
import enhancedAxios from "clients/requestUtil";
import { createContext, PropsWithChildren } from "react";
import useSWR from "swr";

export const CONFIG_DYNAMO = "CONFIG_DYNAMO" as const;

export enum DynamoEnvKeys {
  RPC_NODES = "RPC_NODES",
  USER_ANALYTICS = "USER_ANALYTICS",
  HELLOMOON_LP_ID_SEARCH = "HelloMoonLiquidityPoolIdSearch",
  USE_RPC_PROXY = "useRpcProxy",
  DEV_LANDING_TESTIMONIALS = "DEV_LANDING_TESTIMONIALS",
  DEV_LANDING_MARQUEE = "DEV_LANDING_MARQUEE",

  // DevDash
  DEV_DASH_ANNOUNCEMENTS = "devDashAnnouncements",
  DEV_DASH_QUICKLINKS = "devDashQuicklinks",
}

interface DynamoConfigContextValue {
  config?: Record<string, AttributeValue>[];
  isLoading: boolean;
}

export const DynamoConfigContext = createContext<
  DynamoConfigContextValue | undefined
>(undefined);

export const DynamoConfigContextProvider = ({
  children,
}: PropsWithChildren) => {
  const { data } = useSWR(CONFIG_DYNAMO, async () => {
    const { data } =
      await enhancedAxios.get<Record<string, AttributeValue>[]>(`/api/config/`);

    return data;
  });

  return (
    <DynamoConfigContext.Provider
      value={{
        isLoading: !data,
        config: data,
      }}
    >
      {children}
    </DynamoConfigContext.Provider>
  );
};
